<template>
  <div class="absolute top-0 left-0 w-full min-h-screen mx-auto pt-40 px-20 z-50">
    <div class="relative inline-block w-full h-full m-auto">
      <div class="relative z-10">
        <video-embed></video-embed>
      </div>
    </div>
  </div>
</template>

<script>
import VideoEmbed from "../components/VideoEmbed.vue";
export default {
  components: { VideoEmbed },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
